//INFO: 플랫폼 기본정보 입력 화면

<script>
// TODO:  /v1/setting/info
import { getContent, saveContent } from '@/api/admin/setting.js'
import { ref } from 'vue'
const isLoading = ref(false)

export default {
  name: '',
  components: {},
  data() {
    return {
      companyName: '',
      companyNameEn: '',
      businessNumber: '',
      phoneNumber: '',
      faxNumber: '',
      email: '',
      addressMain: '', // addr1
      addressMainEn: '', // addr1 en
      addressDetail: '', // addr2
      addressDetailEn: '', // addr2 en
      zipCode: '',
      privacyManagerName: '',
      privacyManagerNameEn: '',
      privacyManagerPhoneNumber: '',
      privacyManagerEmail: ''
    }
  },

  setup() {},
  created() {},
  mounted() {
    document.getElementById('basicform').addEventListener('click', function (event) {
      event.preventDefault()
    })

    const getBasicContent = async () => {
      isLoading.value = true

      const data = await getContent()
      // console.log(data)
      this.companyName = data.companyName
      this.companyNameEn = data.companyNameEn
      this.businessNumber = data.businessNumber
      this.phoneNumber = data.phoneNumber
      this.faxNumber = data.faxNumber
      this.email = data.email
      this.addressMain = data.addressMain
      this.addressMainEn = data.addressMainEn
      this.addressDetail = data.addressDetail
      this.addressDetailEn = data.addressDetailEn
      this.zipCode = data.zipCode
      this.privacyManagerName = data.privacyManagerName
      this.privacyManagerNameEn = data.privacyManagerNameEn
      this.privacyManagerPhoneNumber = data.privacyManagerPhoneNumber
      this.privacyManagerEmail = data.privacyManagerEmail

      isLoading.value = false
    }
    getBasicContent()
  },
  unmounted() {},
  methods: {
    // basicSave() {
    //   console.log(this.zip_code + this.addressMain + this.addressDetail)
    // },
    address() {
      this.$daumAddressAPI()
    },

    async basicSave() {
      isLoading.value = true

      const data = {
        infoId: 1,
        companyName: this.companyName,
        companyNameEn: this.companyNameEn,
        businessNumber: this.businessNumber,
        phoneNumber: this.phoneNumber,
        faxNumber: this.faxNumber,
        email: this.email,
        addressMain: this.addressMain,
        addressMainEn: this.addressMainEn,
        addressDetail: this.addressDetail,
        addressDetailEn: this.addressDetailEn,
        zipCode: this.zipCode,
        privacyManagerName: this.privacyManagerName,
        privacyManagerNameEn: this.privacyManagerNameEn,
        privacyManagerPhoneNumber: this.privacyManagerPhoneNumber,
        privacyManagerEmail: this.privacyManagerEmail
      }

      const result = await saveContent(data)

      isLoading.value = false
      if (result === true) {
        alert('저장하였습니다')
      } else {
        alert('실패')
      }
    },

    goMails() {
      this.$router.push('/admin/mails/0')
    }
  }
}
</script>

<style scoped>
.tbl_addr {
  width: 300px;
}
</style>

<template>
  <div id="content-wrapper" class="d-flex flex-column">
    <div id="content">
      <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        <h4>기본환경설정</h4>
      </nav>
      <div class="adminTable">
        <div class="wf__btn-area">
          <button type="button" class="e-btn wf__btn btn-primary" @click="goMails">기업협력 신청 목록</button>
        </div>
        <form action="#" method="post" id="basicform">
          <table>
            <colgroup>
              <col width="12%" />
              <col width="39%" />
              <col width="39%" />
            </colgroup>
            <thead>
              <th></th>
              <th>한글</th>
              <th>영문</th>
            </thead>
            <tbody>
              <tr>
                <th>회사명</th>
                <td><input type="text" name="companyName" v-model="companyName" placeholder="회사명" /></td>
                <td>
                  <input type="text" name="companyNameEn" v-model="companyNameEn" placeholder="company name" />
                </td>
              </tr>
              <tr>
                <th>사업자번호</th>
                <td><input type="text" name="businessNumber" v-model="businessNumber" placeholder="사업자번호" /></td>
                <td></td>
              </tr>
              <tr>
                <th>전화번호</th>
                <td><input type="text" name="phoneNumber" v-model="phoneNumber" placeholder="전화번호" /></td>
                <td></td>
              </tr>
              <tr>
                <th>팩스번호</th>
                <td><input type="text" name="faxNumber" v-model="faxNumber" placeholder="팩스번호" /></td>
                <td></td>
              </tr>
              <tr>
                <th>이메일</th>
                <td><input type="email" name="email" v-model="email" placeholder="이메일" /></td>
                <td></td>
              </tr>
              <tr>
                <th>주소</th>
                <td>
                  <div class="daummap">
                    <input type="text" name="zipCode" v-model="zipCode" placeholder="우편번호" /><br />
                    <input class="tbl_addr" type="text" name="addressMain" v-model="addressMain" placeholder="기본주소" /><br />
                    <input class="tbl_addr" type="text" name="addressDetail" v-model="addressDetail" placeholder="상세 주소" />
                    <button class="d-block e-btn wf__btn btn-primary" @click="address()">주소API 호출</button>
                  </div>
                </td>
                <td>
                  <div class="daummap">
                    <input class="tbl_addr" type="text" name="addressMainEn" v-model="addressMainEn" placeholder="address" /><br />
                    <input class="tbl_addr" type="text" name="addressDetailEn" v-model="addressDetailEn" placeholder="detail address" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>개인정보책임자</th>
                <td>
                  <input type="text" name="privacyManagerName" v-model="privacyManagerName" placeholder="개인정보책임자" />
                </td>
                <td></td>
              </tr>
              <tr>
                <th>개인정보책임자 연락처</th>
                <td>
                  <input
                    type="text"
                    name="privacyManagerPhoneNumber"
                    v-model="privacyManagerPhoneNumber"
                    placeholder="개인정보책임자 연락처"
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <th>개인정보책임자 이메일</th>
                <td>
                  <input type="email" name="privacyManagerEmail" v-model="privacyManagerEmail" placeholder="개인정보책임자 이메일" />
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <button class="e-btn wf__btn btn-success mt-2" type="submit" id="save" @click="basicSave()">저장하기</button>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
#content-wrapper {
  width: var(--wf-container-width);
}
.adminTable input {
  margin: 0.25em 0;
}

.wf__btn-area {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin: 1rem;
  margin-left: 0;
}
</style>
